<template>
  <div v-if="articleReviewSessions">
    <h3 class="content-header-title"> 
      {{ articleTitle }}
    </h3>
    <br>
    <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
      Review sessions
    </h3>
    <h5 style="padding-top:.3rem;"> 
      {{ articleReviewSessions.length }}
    </h5>
    <br>
    <span v-for="(session, index) in articleReviewSessions" :key="index">   
      <b-card>
        <b-row>
          <b-col cols="1"><nobr><strong>Session</strong></nobr></b-col> 
          <b-col cols="2"><span class="text-success"><nobr>{{ session.session.type }}</nobr></span></b-col> 
          <b-col cols="1"><nobr><strong>Started</strong></nobr></b-col> 
          <b-col cols="2">{{ session.session.opened.split('T')[0] }}</b-col>
          <b-col cols="1"><nobr><strong>Admin</strong></nobr></b-col> 
          <b-col cols="2">{{ session.session.administrator.first_name }} {{ session.session.administrator.last_name }}</b-col>
        </b-row>
        <br>
        <b-card v-if="session.reviews && session.reviews.length > 0" bg-variant="light">
          <h5>Reviewed By</h5>
          <br>
          <span v-for="(review, index1) in session.reviews" :key="index1">   
            <b-card>
              <b-row>
                <b-col cols="5"><router-link :to="reviewRouterName(session.session.opened, review.reviewer.id)">{{ review.reviewer.first_name }} {{ review.reviewer.last_name }}</router-link></b-col>
                <b-col cols="2"><strong>Comments:</strong> 5</b-col>
              </b-row>
            </b-card>
          </span>
        </b-card>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          size="sm" 
          @click.stop="addReview(session.session.opened)"
        >
          <feather-icon icon="PlusIcon" />
          Add Review
        </b-button>
      </b-card>
      <br>
    </span>
  </div>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import Ripple from 'vue-ripple-directive'

import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      articleReviewSessions: null,
      article: null,
    }
  },
  computed: {
    articleTitle () {
      let title = this.articleReviewSessions[0].session.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      if (title.length > 100) {
        title = title.substring(0, 99).concat('...')
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
  },
  watch: { 
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  mounted () {
    this.refreshData()
  },
  methods: {
    reviewRouterName (sessionId, reviewerId) {
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          vol: this.$route.params.vol,
          issue: this.$route.params.issue,
          file_name: this.$route.params.file_name,
          session_id: sessionId, 
          reviewer_id: reviewerId,
        }
      }     
      returnDict.name = 'article-review'
      return returnDict
    },
    getArticleReviewSessions () {
      publicationApi.getArticleReviewSessions(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, this.$route.params.file_name, this, null)
    },

    backFromGetArticleReviewSessions (serverData) {
      console.log('bbbbbbb backFromGetArticleReviewSessions', serverData)
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        this.articleReviewSessions = serverData
      } 
    },
    addReview (sessionId) {
      publicationApi.addReview(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, this.$route.params.file_name, sessionId, this, null)
    },

    backFromAddReview (serverData) {
      //console.log('bbbbbbb backFromAddReview', serverData)
      if (serverData.error) {
        console.log('eeeee backFromAddReview', serverData.error)
      } else {
        this.$router.push(this.reviewRouterName(serverData.sessionId, serverData.reviewer.id))
      } 
    },
    refreshData () {
      this.getArticleReviewSessions()
    }
  },
}
</script>
